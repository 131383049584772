const React = require('react');
const { AuthProvider } = require('./src/context/AuthContext');
const { PackageProvider } = require('./src/context/PackageContext');
const { ApplicationProvider } = require('./src/context/ApplicationContext');

const addScript = (url, id) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.id = id;
  document.body.appendChild(script);
};

const addScriptWithContent = (content, id) => {
  const script = document.createElement('script');
  script.innerHTML = content;
  script.async = true;
  script.id = id;
  document.body.appendChild(script);
};

const addGoogleTagManager = () => {
  addScript(
    'https://www.googletagmanager.com/gtag/js?id=G-X5PKGSBL03',
    'google-tag-manager-script'
  );
  addScriptWithContent(
    `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-X5PKGSBL03");
    `,
    'google-tag-manager'
  );
};

const addPardot = () => {
  addScriptWithContent(
    `
  piAId = '1032921';
  piCId = '13042';
  piHostname = 'ww3.readingandwritingproject.com';
  
  (function() {
  function async_load(){
  var s = document.createElement('script'); s.type = 'text/javascript';
  s.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + piHostname + '/pd.js';
  var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
  }
  if(window.attachEvent) { window.attachEvent('onload', async_load); }
  else { window.addEventListener('load', async_load, false); }
  })();
  `,
    'pardot'
  );
};

export const onInitialClientRender = () => {
  addGoogleTagManager();
  addPardot();
};

export const wrapRootElement = ({ element, props }) => {
  return (
    <AuthProvider>
      <PackageProvider>
        <ApplicationProvider>{element}</ApplicationProvider>
      </PackageProvider>
    </AuthProvider>
  );
};
