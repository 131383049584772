import React, { createContext, useEffect, useState } from 'react';
import { parseUser } from '../components/members/Util';
import { getUserInfo } from '../util/auth';
import { UserInfo } from './User';
import { User } from '../components/members/NewApiTypes.generated';

function canAccessToolkit(userInfo: User) {
  return !!userInfo.contentAccess?.toolKitAccess;
}

function isTestPrep(userInfo: User) {
  return !!userInfo.contentAccess?.testPrepAccess;
}

function isSchoolLeader(userInfo: User) {
  return !!userInfo.contentAccess?.schoolLeaderAccess;
}

function hasRole(userInfo: UserInfo, requiredRole: number) {
  return false;
}

interface AuthProviderProps {
  children: React.ReactChildren;
}

const AuthContext = createContext<{
  authState: User | UserInfo | null | undefined;
  setAuthState: (authInfo: UserInfo | User) => void;
  canAccessToolkit: (userInfo: User) => boolean;
  reloadUser: () => void;
  isSchoolLeader: (userInfo: User) => boolean;
  isTestPrep: (userInfo: User) => boolean;
  hasRole: (userInfo: UserInfo, requiredRole: number) => boolean;
  userInfo: any;
}>({
  authState: undefined,
  setAuthState: () => null,
  canAccessToolkit: () => false,
  isSchoolLeader: () => false,
  isTestPrep: () => false,
  reloadUser: () => null,
  hasRole: () => false,
  userInfo: null
});
const { Provider } = AuthContext;

const AuthProvider = (props: AuthProviderProps) => {
  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await getUserInfo();

        setAuthState(user || null);
      } catch (err) {
        console.log('error', err);
      }
    };

    getUser();
  }, []);

  const [authState, setAuthState] = useState<
    User | UserInfo | null | undefined
  >(undefined);

  const reloadUser = async () => {
    const user = await getUserInfo();
    setAuthState(user || null);
  };
  return (
    <Provider
      value={{
        authState: authState,
        setAuthState: (authInfo: any) => setAuthState(authInfo),
        canAccessToolkit,
        reloadUser,
        isSchoolLeader,
        isTestPrep,
        hasRole,
        userInfo: parseUser(authState)
      }}
    >
      {props.children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
