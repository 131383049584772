import { getCookie } from './cookie';

export const getUserInfo = async () => {
  const token = getCookie('access_token');
  if (!token) {
    return null;
  }
  try {
    const req = await fetch(`${process.env.GATSBY_API_URL}/userInfo`, {
      headers: {
        'auth-provider': 'auth0',
        Authorization: `Bearer ${token}`
      }
    });

    if (!req.ok) {
      return null;
    }

    const res = await req.json();

    return res.data;
  } catch (err) {
    throw new Error('There was a problem getting the user');
  }
};
