export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value}; domain=${process.env.PUBLIC_DOMAIN}; path=/`;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`) as any[];
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=; domain=${process.env.PUBLIC_DOMAIN}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};
